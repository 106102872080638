const libraryConstants = {
  GET_ALL_LIBRARY_REQUEST: 'GET_ALL_LIBRARY_REQUEST',
  GET_ALL_LIBRARY_SUCCESS: 'GET_ALL_LIBRARY_SUCCESS',
  GET_ALL_LIBRARY_FAILURE: 'GET_ALL_LIBRARY_FAILURE',

  GET_LIBRARY_REQUEST: 'GET_LIBRARY_REQUEST',
  GET_LIBRARY_SUCCESS: 'GET_LIBRARY_SUCCESS',
  GET_LIBRARY_FAILURE: 'GET_LIBRARY_FAILURE',

  GET_APIS_REQUEST: 'GET_APIS_REQUEST',
  GET_APIS_SUCCESS: 'GET_APIS_SUCCESS',
  GET_APIS_FAILURE: 'GET_APIS_FAILURE',

  GET_API_REQUEST: 'GET_API_REQUEST',
  GET_API_SUCCESS: 'GET_API_SUCCESS',
  GET_API_FAILURE: 'GET_API_FAILURE',
  RESET_API_DETAILED: 'RESET_API_DETAILED',

  GET_API_HOSTNAMES_REQUEST: 'GET_API_HOSTNAMES_REQUEST',
  GET_API_HOSTNAMES_SUCCESS: 'GET_API_HOSTNAMES_SUCCESS',
  GET_API_HOSTNAMES_FAILURE: 'GET_API_HOSTNAMES_FAILURE',
  RESET_API_HOSTNAMES: 'RESET_API_HOSTNAMES',

  GET_APIS_TAGS_REQUEST: 'GET_APIS_TAGS_REQUEST',
  GET_APIS_TAGS_SUCCESS: 'GET_APIS_TAGS_SUCCESS',
  GET_APIS_TAGS_FAILURE: 'GET_APIS_TAGS_FAILURE',

  GET_API_OPENAPI_REQUEST: 'GET_API_OPENAPI_REQUEST',
  GET_API_OPENAPI_SUCCESS: 'GET_API_OPENAPI_SUCCESS',
  GET_API_OPENAPI_FAILURE: 'GET_API_OPENAPI_FAILURE',

  FILTER_ALL_LIBRARY: 'FILTER_ALL_LIBRARY',

  RESET_LIBRARY: 'RESET_LIBRARY',

  RESET_LIBRARY_API: 'RESET_LIBRARY_API',

  GET_LIBRARY_SKIP: 'GET_LIBRARY_SKIP',
  GET_APIS_PRODUCT_SKIP: 'GET_APIS_PRODUCT_SKIP',

  GET_API_DESCRIPTION_REQUEST: 'GET_API_DESCRIPTION_REQUEST',
  GET_API_DESCRIPTION_SUCCESS: 'GET_API_DESCRIPTION_SUCCESS',
  GET_API_DESCRIPTION_FAILURE: 'GET_API_DESCRIPTION_FAILURE',

  GET_API_PRODUCTS_REQUEST: 'GET_API_PRODUCTS_REQUEST',
  GET_API_PRODUCTS_SUCCESS: 'GET_API_PRODUCTS_SUCCESS',
  GET_API_PRODUCTS_FAILURE: 'GET_API_PRODUCTS_FAILURE',

  SELECTED_APIS: 'SELECTED_APIS',

};

export default libraryConstants;
