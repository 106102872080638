import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';

import homeReducer from './reducer/homeReducer';
import faqReducer from './reducer/faqReducer';
import blogReducer from './reducer/blogReducer';
import libraryReducer from './reducer/libraryReducer';
import userReducer from './reducer/userReducer';
import usersReducer from './reducer/usersReducer';
import productReducer from './reducer/productReducer';
import subscriptionsReducer from './reducer/subscriptionsReducer';
import apiReducer from './reducer/apiReducer';
import mailReducer from './reducer/mailReducer';
import termReducer from './reducer/termReducer';
import policyReducer from './reducer/policyReducer';
import timeReducer from './reducer/timeReducer';
import groupReducer from './reducer/groupReducer';
import appsReducer from './reducer/appsReducer';
import gettingStartedReducer from './reducer/gettingStartedReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
  home: homeReducer,
  faq: faqReducer,
  blog: blogReducer,
  library: libraryReducer,
  user: userReducer,
  products: productReducer,
  users: usersReducer,
  subscriptions: subscriptionsReducer,
  api: apiReducer,
  mail: mailReducer,
  term: termReducer,
  policy: policyReducer,
  timer: timeReducer,
  group: groupReducer,
  apps: appsReducer,
  started: gettingStartedReducer,
});

const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(thunk),
  ),
);

export default store;
