import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Toaster } from 'react-hot-toast';
import { MdOutlineWarning } from 'react-icons/md';
import useTimer from '../../hooks/useTimer';
import useVerifySession from '../../hooks/useVerifySession';

import SidebarDrawer from '../../components/SidebarDrawer/SidebarDrawer';
import CustomFooter from '../../components/common/CustomFooter/CustomFooter';
import SkeletonComponent from '../../components/SkeletonComponent/SkeletonComponent';

import Profile from '../../pages/private/Profile';
import Products from '../../pages/private/Products';
import Users from '../../pages/private/Users';
import ProductDetail from '../../pages/private/ProductDetail';
import UserDetail from '../../pages/private/UserDetail';
import Apis from '../../pages/private/Apis';
import ApiDetail from '../../pages/private/ApiDetail';
import SwaggerUI from '../../pages/private/SwaggerUI';
import OAuthRedirect from '../../pages/private/OAuthRedirect';
//import Subscriptions from '../../pages/private/Subscriptions';
import CicleTexture from '../../static/img/texture_circles_private.svg';
import Logout from '../../pages/private/Logout/Logout';
import Groups from '../../pages/private/Groups';
import GroupDetailed from '../../pages/private/Groups/GroupDetailed';

import { getUser, getUserGroups } from '../../redux/actions/userAction';
import classes from './private-router.module.scss';
import Apps from '../../pages/private/Apps/Apps';
import AppDetailed from '../../pages/private/Apps/AppDetailed';
import AddApp from '../../pages/private/Apps/AddApp';
import GettingStarted from '../../pages/private/GettingStarted';
import AddUserB2c from '../../pages/private/Apps/AddUserB2c';
import config from '../../services/config';

function PrivateRouter({ children }) {
  // const [showModal, setShowModal] = useState(false);
  const { id, token, user, openModal, userGroups } = useSelector((state) => state.user);
  const { time } = useSelector((state) => state.timer);
  const { checkSession } = useVerifySession();
  const { getTime } = useTimer();
  const privateSession = id !== '' && token !== '';
  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== '' && token !== '' && user && Object.keys(user).length === 0) {
      const tokens = {
        userId: {
          id,
        },
        token,
      };
      dispatch(getUser(tokens));
      if (user) dispatch(getUserGroups(tokens));
      getTime();

    }
  }, []);

  useEffect(() => {
    checkSession();
  }, [time]);

  const isAdmin = userGroups?.value.find((group) => group.name === config.adminId);

  return privateSession ? (
    <Box>
      {
        openModal &&
        (<Logout showModal={openModal} setShowModal={openModal} />)
      }
      {user && Object.keys(user).length > 0 ? (
        <Box>
          <Box sx={{ display: 'flex', flex: '1', backgroundColor: '#fbfbfb', minHeight: '100vh' }}>
            <Toaster
              position='top-right'
              toastOptions={{
                icon: <MdOutlineWarning color='#F03738' size='1.5rem' />,
                style: {
                  border: '1px solid #F03738',
                  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F03738',
                  color: '#000',
                  fontWeight: 600,
                },
              }}
            />
            <SidebarDrawer user={user} isAdmin={isAdmin} />
            <div className={classes.texture}>
              <img src={CicleTexture} alt={CicleTexture} />
            </div>
            <div className={`container ${classes.wrapper}`}>
              <Routes>
                <Route path='profile' element={<Profile />} />
                <Route path='products' exact='true' element={<Products />} />
                <Route path='products/:id' exact='true' element={<ProductDetail />} />
                <Route path='apis' exact='true' element={<Apis />} />
                <Route path='apis/:id' exact='true' element={<ApiDetail />} />
                <Route path='apis/:id/swagger-ui' exact='true' element={<SwaggerUI />} />
                <Route path='apis/swagger-ui/oauth-redirect' exact='true' element={<OAuthRedirect />} />
                {/*<Route path='subscriptions' exact='true' element={<Subscriptions />} />*/}
                {isAdmin && (
                  <>
                    <Route path='users' exact='true' element={<Users />} />
                    <Route path='users/:id' exact='true' element={<UserDetail />} />
                    <Route path='groups' exact='true' element={<Groups />} />
                    <Route path='groups/:id' exact='true' element={<GroupDetailed />} />
                  </>
                )}
                <Route path='apps' element={<Apps />} />
                <Route path='apps/:id' element={<AppDetailed />} />
                <Route path='apps/new-app' element={<AddApp />} />
                <Route path='apps/user-b2c' element={<AddUserB2c />} />
                <Route path='getting-started' element={<GettingStarted />} />
                <Route path='*' element={<Navigate to='/' replace />} />
              </Routes>
            </div>
          </Box>
          <Box sx={{ zIndex: 1300, position: 'absolute', background: '#fff', width: '100%' }}>
            <CustomFooter />
          </Box>

        </Box>
      ) : (<SkeletonComponent />)}

    </Box>
  ) : <Navigate to='/' />;

}

export default PrivateRouter;
