const apiConstants = {
  GET_ALL_API_REQUEST: 'GET_ALL_API_REQUEST',
  GET_ALL_API_SUCCESS: 'GET_ALL_API_SUCCESS',
  GET_ALL_API_FAILURE: 'GET_ALL_API_FAILURE',
  GET_API_PRODUCTS_REQUEST: 'GET_API_PRODUCTS_REQUEST',
  GET_API_PRODUCTS_SUCCESS: 'GET_API_PRODUCTS_SUCCESS',
  GET_API_PRODUCTS_FAILURE: 'GET_API_PRODUCTS_FAILURE',
};

export default apiConstants;
