const mailConstants = {
  GET_ALL_MAIL_CONTACT_REQUEST: 'GET_ALL_MAIL_CONTACT_REQUEST',
  GET_ALL_MAIL_CONTACT_SUCCESS: 'GET_ALL_MAIL_CONTACT_SUCCESS',
  GET_ALL_MAIL_CONTACT_FAILURE: 'GET_ALL_MAIL_CONTACT_FAILURE',
  GET_ALL_MAIL_CONVERSATION_REQUEST: 'GET_ALL_MAIL_CONVERSATION_REQUEST',
  GET_ALL_MAIL_CONVERSATION_SUCCESS: 'GET_ALL_MAIL_CONVERSATION_SUCCESS',
  GET_ALL_MAIL_CONVERSATION_FAILURE: 'GET_ALL_MAIL_CONVERSATION_FAILURE',
};

export default mailConstants;
