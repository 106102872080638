import handleResponse from './handleResponse';
import config from './config';
import store from '../redux/store';

const getApiContent = () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(`${config.apiUrl}/pages?_where[slug]=${config.apisPageSlug}&_locale=${config.locale}`, requestOptions)
    .then(handleResponse)
    .then((api_content) => {
      return api_content[0];
    }).catch((error) => {
      console.error(error);
    });
};

const getApiProducts = async (apiId) => {
  const { token } = store.getState().user;
  const requestOptions = {
    method: 'GET',
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(`${config.apimUrl}/apis/${apiId}/products`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
};

const apiService = {
  getApiContent,
  getApiProducts,
};

export default apiService;
