const appsConstants = {

  LIST_ALL_APPS_REQUEST: 'LIST_ALL_APPS_REQUEST',
  LIST_ALL_APPS_SUCCESS: 'LIST_ALL_APPS_SUCCESS',
  LIST_ALL_APPS_ERROR: 'LIST_ALL_APPS_ERROR',

  GET_DETAIL_APP_REQUEST: 'GET_DETAIL_APP_REQUEST',
  GET_DETAIL_APP_SUCCESS: 'GET_DETAIL_APP_SUCCESS',
  GET_DETAIL_APP_ERROR: 'GET_DETAIL_APP_ERROR',

  GET_DETAIL_APP_SUSCRIPTION_REQUEST: 'GET_DETAIL_APP_SUSCRIPTION_REQUEST',
  GET_DETAIL_APP_SUSCRIPTION_SUCCESS: 'GET_DETAIL_APP_SUSCRIPTION_SUCCESS',
  GET_DETAIL_APP_SUSCRIPTION_ERROR: 'GET_DETAIL_APP_SUSCRIPTION_ERROR',

  GET_DETAIL_APP_APIS_REQUEST: 'GET_DETAIL_APP_APIS_REQUEST',
  GET_DETAIL_APP_APIS_SUCCESS: 'GET_DETAIL_APP_APIS_SUCCESS',
  GET_DETAIL_APP_APIS_ERROR: 'GET_DETAIL_APP_APIS_ERROR',

  ADD_API_APP_REQUEST: 'ADD_API_APP_REQUEST',
  ADD_API_APP_SUCCESS: 'ADD_API_APP_SUCCESS',
  ADD_API_APP_ERROR: 'ADD_API_APP_ERROR',

  DELETE_API_APP_REQUEST: 'DELETE_API_APP_REQUEST',
  DELETE_API_APP_SUCCESS: 'DELETE_API_APP_SUCCESS',
  DELETE_API_APP_ERROR: 'DELETE_API_APP_ERROR',

  CREATE_APP_REQUEST: 'CREATE_APP_REQUEST',
  CREATE_APP_SUCCESS: 'CREATE_APP_SUCCESS',
  CREATE_APP_ERROR: 'CREATE_APP_ERROR',

  UPDATE_APP_REQUEST: 'UPDATE_APP_REQUEST',
  UPDATE_APP_SUCCESS: 'UPDATE_APP_SUCCESS',
  UPDATE_APP_ERROR: 'UPDATE_APP_ERROR',

  GET_SECRET_KEYS_REQUEST: 'GET_SECRET_KEYS_REQUEST',
  GET_SECRET_KEYS_SUCCESS: 'GET_SECRET_KEYS_SUCCESS',
  GET_SECRET_KEYS_ERROR: 'GET_SECRET_KEYS_ERROR',

  FILTER_APPS_BY_NAME_REQUEST: 'FILTER_APPS_BY_NAME_REQUEST',
  FILTER_APPS_BY_NAME_SUCCESS: 'FILTER_APPS_BY_NAME_SUCCESS',
  FILTER_APPS_BY_NAME_ERROR: 'FILTER_APPS_BY_NAME_ERROR',

  FILTER_APPS_BY_DATE_REQUEST: 'FILTER_APPS_BY_DATE_REQUEST',
  FILTER_APPS_BY_DATE_SUCCESS: 'FILTER_APPS_BY_DATE_SUCCESS',
  FILTER_APPS_BY_DATE_ERROR: 'FILTER_APPS_BY_DATE_ERROR',

  GET_APPS_SKIP: 'GET_APPS_SKIP',
  GET_APPS_API_SKIP: 'GET_APPS_API_SKIP',

  REGENERATE_PRIMARY_KEY_REQUEST: 'REGENERATE_PRIMARY_KEY_REQUEST',
  REGENERATE_PRIMARY_KEY_SUCCESS: 'REGENERATE_PRIMARY_KEY_SUCCESS',
  REGENERATE_PRIMARY_KEY_ERROR: 'REGENERATE_PRIMARY_KEY_ERROR',

  REGENERATE_SECONDARY_KEY_REQUEST: 'REGENERATE_PRIMARY_KEY_REQUEST',
  REGENERATE_SECONDARY_KEY_SUCCESS: 'REGENERATE_PRIMARY_KEY_SUCCESS',
  REGENERATE_SECONDARY_KEY_ERROR: 'REGENERATE_PRIMARY_KEY_ERROR',

  REGENERATE_CLIENT_SECRET_REQUEST: 'REGENERATE_CLIENT_SECRET_REQUEST',
  REGENERATE_CLIENT_SECRET_SUCCESS: 'REGENERATE_CLIENT_SECRET_SUCCESS',
  REGENERATE_CLIENT_SECRET_ERROR: 'REGENERATE_CLIENT_SECRET_ERROR',

  RESET_ERRORS: 'RESET_ERRORS',
  RESET_APPS: 'RESET_APPS',

  REGISTER_USER_B2C_REQUEST: 'REGISTER_USER_B2C_REQUEST',
  REGISTER_USER_B2C_SUCCESS: 'REGISTER_USER_B2C_SUCCESS',
  REGISTER_USER_B2C_ERROR: 'REGISTER_USER_B2C_ERROR',
  RESET_B2C_ALERT: 'RESET_B2C_ALERT',

  DELETE_APP_REQUEST: 'DELETE_APP_REQUEST',
  DELETE_APP_SUCCESS: 'DELETE_APP_SUCCESS',
  DELETE_APP_ERROR: 'DELETE_APP_ERROR',

  LIST_ALLOWED_APPS_REQUEST: 'LIST_ALLOWED_APPS_REQUEST',
  LIST_ALLOWED_APPS_SUCCESS: 'LIST_ALLOWED_APPS_SUCCESS',
  LIST_ALLOWED_APPS_ERROR: 'LIST_ALLOWED_APPS_ERROR',

  NOTIFY_ALLOWED_APPS_REQUEST: 'NOTIFY_ALLOWED_APPS_REQUEST',
  NOTIFY_ALLOWED_APPS_SUCCESS: 'NOTIFY_ALLOWED_APPS_SUCCESS',
  NOTIFY_ALLOWED_APPS_ERROR: 'NOTIFY_ALLOWED_APPS_ERROR',
  RESET_NOTIFICATION_SUCC_MSG: 'RESET_NOTIFICATION_SUCC_MSG',

  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
};

export default appsConstants;
