const usersConstants = {
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

  GET_USERS_SKIP: 'GET_USERS_SKIP',

  GET_USER_DETAIL_REQUEST: 'GET_USER_DETAIL_REQUEST',
  GET_USER_DETAIL_SUCCESS: 'GET_USER_DETAIL_SUCCESS',
  GET_USER_DETAIL_FAILURE: 'GET_USER_DETAIL_FAILURE',

  RESET_USERS: 'RESET_USERS',

};

export default usersConstants;
