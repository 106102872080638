/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import SwaggerUi from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

import { Container } from '@mui/material';
import Icon from '../../../components/MdIcon/Icon';
import libraryService from '../../../services/libraryService';
import appsService from '../../../services/appsService';
//import apiService from '../../../services/apiService';
//import productService from '../../../services/productsService';
import classes from './swagger-ui.module.scss';
//import Select from '../../../components/Input/InputUI/Select';
import config from '../../../services/config';

function SwaggerUI() {

  const params = useParams();
  const [docCheck, setDocCheck] = useState(false);
  //const [apps, setApps] = useState([]);
  //const [products, setProducts] = useState([]);
  //const [subscriptions, setSubscriptions] = useState([]);
  //const [swaggerUi, setSwaggerUi] = useState();
  /*const [openApiFormat, setOpenApiFormat] = useState();

  const hasApiKeySecurity = () => {
    if (!openApiFormat) return false;
    switch (openApiFormat) {
      case 'swagger':
        return true;
      case 'openapi':
        return true;
      default: return false;
    }
  };

  const hasOAuthSecurity = () => {
    if (!openApiFormat) return false;
    switch (openApiFormat) {
      case 'swagger':
        return true;
      case 'openapi':
        return true;
      default: return false;
    }
  };

  const selectEnvHost = () => {
    if (!openApiFormat) return '';
    switch (openApiFormat) {
      case 'swagger':
        return true;
      case 'openapi':
        return true;
      default: return false;
    }
  };*/

  const updateScopes = (openapi, apps) => {
    for (const [, securityScheme] of Object.entries(openapi.components?.securitySchemes)) {
      if (securityScheme.type === 'oauth2') {
        for (const [typeFlow, flow] of Object.entries(securityScheme.flows)) {
          if (typeFlow === 'clientCredentials') {
            for (const app of apps) {
              flow.scopes[`${config.b2cBaseUrl}/${app.id}/.default`] = 'Grant access to data';
            }
            continue;
          }
          if (typeFlow === 'password' || typeFlow === 'authorizationCode') {
            for (const app of apps) {
              flow.scopes[`${app.id}`] = 'Grant access to data';
            }
            continue;
          }
        }
      }
    }
    return openapi;
  };

  const updateServers = (openapi, hostnames) => {
    const customOpenApi = { ...openapi };
    const servers = customOpenApi && customOpenApi['servers'] !== undefined && customOpenApi.servers.length > 0 ? customOpenApi.servers.filter((server) => {
      const url = new URL(server.url);
      if (hostnames.includes(url.hostname)) return true;
      return false;
    }) : [];
    customOpenApi['servers'] = servers;
    return customOpenApi;
  };

  useEffect(() => {
    appsService.listAllApps(0).then((appList) => {
      const apps = appList && Object.keys(appList).length > 0 ? appList.data.filter((app) => app.apis.some((api) => api.id === params.id)).map((app) => {
        const appItem = {
          ...app,
          id: app.appId,
          value: app.appId,
          text: app.displayName,
        };
        return appItem;
      }) : [];
      //setApps(apps);

      libraryService.getApiHostnames(params.id).then((hostnameList) => {
        const hostnames = hostnameList.data && Object.keys(hostnameList.data).length > 0 ? hostnameList.data.value.map((hostname) => {
          return hostname.properties.value;
        }) : [];

        libraryService.getApiOpenAPI(params.id).then((jsonOpenApi) => {
          //setOpenApiFormat(('swagger' in jsonOpenApi) ? 'swagger' : 'openapi');
          //hasApiKeySecurity();
          //hasOAuthSecurity();
          //selectEnvHost();
          let customOpenApi = updateScopes(jsonOpenApi, apps);
          customOpenApi = updateServers(customOpenApi, hostnames);

          SwaggerUi({
            dom_id: '#swaggerContainer',
            spec: customOpenApi,
            presets: SwaggerUi.presets.apis,
            oauth2RedirectUrl: `${window.location.protocol}//${window.location.host}/developer/apis/swagger-ui/oauth-redirect`,
            persistAuthorization: false,
          });
          //setSwaggerUi(swaggerUi);
        }).catch((e) => {
          setDocCheck(true);
        });;

      });
    });

    /*apiService.getApiProducts(params.id).then((productList) => {
      const products = productList && Object.keys(productList).length > 0 ? productList.value.map((product) => {
        const prodItem = {
          ...product.properties,
          id: product.name,
          value: product.name,
          text: product.properties.displayName,
        };
        return prodItem;
      }) : [];
      console.log(products);
      setProducts(products);
    });*/
  }, []);

  /*const handleApplicationSelect = (selectedApp) => {
    console.log(selectedApp);
  };

  const handleProductSelect = (selectProduct) => {
    productService.getProductSuscripcion(selectProduct.id).then((subscriptionList) => {
      console.log(subscriptionList);
      const subscriptions = subscriptionList.data && Object.keys(subscriptionList.data).length > 0 ? subscriptionList.data.value.map((subscription) => {
        const subscriptionItem = {
          ...subscription.properties,
          id: subscription.name,
          value: subscription.name,
          text: subscription.properties.displayName,
        };
        return subscriptionItem;
      }) : [];
      setSubscriptions(subscriptions);
    });
  };

  const handleSubscriptionSelect = (selectedSubscription) => {
    console.log(selectedSubscription);
  };*/

  /*const loadOAuth = () => {
    for (const [, securityScheme] of Object.entries(swagger.components?.securitySchemes)) {
      if (securityScheme.type === 'oauth2') {
        for (const [typeFlow, flow] of Object.entries(securityScheme.flows)) {
          console.log('typeFlow >>>>>> ', typeFlow);
          if (typeFlow === 'clientCredentials') {
            console.log(flow.scopes, config.b2cBaseUrl);
            flow.scopes[`${config.b2cBaseUrl}/5014926c-0314-470e-a65a-d599877fef0a/.default`] = 'Grant access to data';
            continue;
          }
          if (typeFlow === 'password' || typeFlow === 'authorizationCode') {
            console.log(flow.scopes, config.b2cBaseUrl);
            flow.scopes['5014926c-0314-470e-a65a-d599877fef0a'] = 'Grant access to data';
            continue;
          }
        }
      }
    }

    const swaggerUi = SwaggerUi({
      dom_id: '#swaggerContainer',
      spec: swagger,
      oauth2RedirectUrl: `${window.location.protocol}//${window.location.host}/developer/apis/swagger-ui/oauth-redirect`,
      persistAuthorization: true,
    });

    swaggerUi.initOAuth({
      clientId: '5014926c-0314-470e-a65a-d599877fef0a',
      clientSecret: 'Kgo8Q~Rxzm4KXKItP2j5_joKGO1toMRjxWtPQaAg',
      //realm: 'your-realms',
      //appName: 'your-app-name',
      scopeSeparator: ' ',
      scopes: 'https://suramexicob2capimarketdev.onmicrosoft.com/5014926c-0314-470e-a65a-d599877fef0a/.default 5014926c-0314-470e-a65a-d599877fef0a',
      //additionalQueryStringParams: { test: 'hello' },
      //useBasicAuthenticationWithAccessCodeGrant: true,
      //usePkceWithAuthorizationCodeGrant: true,
    });

    swaggerUi.preauthorizeApiKey('apiKeyHeader', '014926c-0314-470e-a65a-d599877fef0a');

    return true;
  };*/

  return (
    <>
      <div className={classes.back__btn}>
        <Link to={-1}>
          <div className={classes.return}>
            <div>
              <Icon id='MdKeyboardBackspace' />
            </div>
            <span>VOLVER</span>
          </div>
        </Link>
      </div>
      {/* apps.length > 0 && (
        <Container fixed sx={{ paddingLeft: { xs: '0px', md: '59px !important' }, paddingRight: { xs: '0px', md: '97px !important' } }}>
          <div className='row mt-6'>
            <div className='flex-sm-12 flex-md-12 flex-lg-12'>
              <Select label='Aplicación' placeholder='Seleccione una aplicación' items={apps} itemText='text' itemValue='value' onChange={(e) => handleApplicationSelect(e)} />
            </div>
          </div>
        </Container>
      )*/}
      {/* products.length > 0 && (
        <Container fixed sx={{ paddingLeft: { xs: '0px', md: '59px !important' }, paddingRight: { xs: '0px', md: '97px !important' } }}>
          <div className='row mt-6'>
            <div className='flex-sm-12 flex-md-12 flex-lg-12'>
              <Select label='Producto' placeholder='Seleccione un producto' items={products} itemText='text' itemValue='value' onChange={(e) => handleProductSelect(e)} />
            </div>
          </div>
          <div className='row mt-6'>
            <div className='flex-sm-12 flex-md-12 flex-lg-12'>
              <Select label='Suscripción' disabled={!subscriptions.length > 0} placeholder='Seleccione una suscripción' items={subscriptions} itemText='text' itemValue='value' onChange={(e) => handleSubscriptionSelect(e)} />
            </div>
          </div>
        </Container>
      )*/}
      <Container fixed sx={{ paddingLeft: { xs: '0px', md: '59px !important' }, paddingRight: { xs: '0px', md: '97px !important' } }}>
        {
          docCheck ? <h1>Información no disponible</h1> : <div id='swaggerContainer' />
        }
      </Container>
    </>
  );
};

export default SwaggerUI;
