/* eslint-disable no-use-before-define */
import userConstants from '../constants/userConstats';
import userService from '../../services/userService';
import config from '../../services/config';
import { getUserDetail, listUsers } from './usersAction';

// eslint-disable-next-line import/prefer-default-export
export const login = (data) => (dispatch) => {
  dispatch({ type: userConstants.LOGIN_REQUEST });
  userService.login(data.email, data.password).then((response) => {
    if (response && Object.keys(response).length > 0) {
      if (Object.prototype.hasOwnProperty.call(response, 'error')) {
        dispatch({
          type: userConstants.LOGIN_FAILURE,
          error: response,
        });
      } else {
        if (data.remember) {
          const passwordEncrypted = btoa(data.password);
          const secureKeyEncrypted = btoa(`${passwordEncrypted}:${config.rememberkey}`);
          localStorage.setItem('email', data.email);
          localStorage.setItem('password', secureKeyEncrypted);
        }
        sessionStorage.setItem('token', JSON.stringify(response.data));
        dispatch(getUser(response.data));
        dispatch(getUserGroups(response.data));
        dispatch({ type: userConstants.RESET_ALERT });
      }
    }
  }, (error) => {
    console.error('error', error);
  });

};

export const confirmAccount = (queryParams, setIsOpen) => (dispatch) => {
  dispatch({
    type: userConstants.CONFIRM_ACCOUNT_REQUEST,
  });
  userService.confirmAccount(queryParams).then(
    (response) => {
      if (response && Object.keys(response).length > 0) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
          dispatch({
            type: userConstants.CONFIRM_ACCOUNT_FAILURE,
            response,
          });
        } else {
          dispatch({
            type: userConstants.CONFIRM_ACCOUNT_SUCCESS,
            response: response.data,
          });
        }
      }
    },
  );
};

export const logout = () => (dispatch) => {
  window.location.reload(false);
  sessionStorage.removeItem('token');
  localStorage.removeItem('If-Match');
  dispatch({
    type: userConstants.LOGOUT_USER,
  });
};

export const signUp = (data) => (dispatch) => {
  dispatch({ type: userConstants.SIGNUP_REQUEST });
  userService.signUp(data).then(
    (response) => {
      if (response && Object.keys(response).length > 0) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
          dispatch({
            type: userConstants.SIGNUP_FAILURE,
            response,
          });
        } else {
          dispatch({
            type: userConstants.SIGNUP_SUCCESS,
            response: response.data,
          });
        }
      }
    },
    (error) => {
      console.error(error);
    },
  );
};

export const getUser = (tokens) => (dispatch) => {
  dispatch({ type: userConstants.GET_USER_REQUEST });
  userService.getUserDetails(tokens.token, tokens?.userId?.id).then(
    (response) => {
      if (response && Object.keys(response).length > 0) {
        dispatch({ type: userConstants.GET_USER_SUCCESS, response });
        dispatch({
          type: userConstants.LOGIN_SUCCESS,
          id: tokens?.userId?.id,
          token: tokens?.token,
        });
      } else {
        sessionStorage.removeItem('token');
        dispatch({ type: userConstants.LOGOUT_USER });
      }
    },
    (error) => {
      console.error(error);
    },
  );
};

export const getUserGroups = (tokens) => (dispatch) => {
  dispatch({ type: userConstants.GET_USER_GROUPS_REQUEST });
  userService.getUserGroups(tokens?.token, tokens?.userId?.id).then(
    (response) => {
      dispatch({ type: userConstants.GET_USER_GROUPS_SUCCESS, response });
    },
  );
};

/*export const getUserEntityTag = (tokens) => (dispatch) => {
  userService.getUserEntityTag(tokens.token, tokens?.userId?.id).then(
    (response) => {
      localStorage.setItem('If-Match', JSON.stringify(response));
      dispatch({ type: userConstants.HEAD_ETAG_SUCCESS, response });
    },
    (error) => {
      console.error(error);
    },
  );
};*/

export const updateUser = (data, userId, tokens) => (dispatch) => {
  dispatch({
    type: userConstants.UPDATE_USER_PROFILE_REQUEST,
  });
  userService.updateUser(data, userId).then(
    (response) => {
      if (response && Object.keys(response).length > 0) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
          dispatch({
            type: userConstants.UPDATE_USER_PROFILE_FAILURE,
            response,
          });
        } else {
          dispatch({
            type: userConstants.UPDATE_USER_PROFILE_SUCCESS,
            response,
          });
        }
      }
    },
  ).finally(() => {
    dispatch(getUser(tokens));
  });
};

export const changeStatus = (data, userId) => (dispatch) => {
  dispatch({
    type: userConstants.UPDATE_USER_STATUS_REQUEST,
  });
  userService.changeStatus(data, userId)
    .then((response) => {
      if (Object.keys(response).length > 0) {
        if (Object.prototype.hasOwnProperty.call(response, 'errors')) {
          dispatch({
            type: userConstants.UPDATE_USER_STATUS_FAILURE,
            payload: response,
          });
          dispatch(resetAlert());
        } else {
          dispatch({
            type: userConstants.UPDATE_USER_STATUS_SUCCESS,
            payload: response.data,
          });
        }
      }
    }).finally(() => {
      dispatch(getUserDetail(userId));
      dispatch(listUsers());
    });
};

/*export const verifyOldPassword = (data) => (dispatch) => {
  dispatch({
    type: userConstants.RESTORE_SIGNUP_REQUEST,
  });
  userService.verifyOldPassword(data).then(
    (response) => {
      if (response && Object.keys(response).length > 0) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
          if (response?.error?.status === 401) {
            dispatch({
              type: userConstants.RESTORE_SIGNUP_FAILURE,
              response,
            });
          }
        } else {
          dispatch({
            type: userConstants.RESTORE_SIGNUP_SUCCESS,
            response,
          });
          dispatch(changePassword(data.new_password, response?.data?.id));
        }
      }
    },
    (error) => {
      console.error(error);
    },
  );
};*/

export const changePassword = (data) => (dispatch) => {
  dispatch({
    type: userConstants.CHANGE_PASSWORD_LOGGED_REQUEST,
  });
  userService.changePassword(data).then(
    (response) => {
      if (response && Object.keys(response).length > 0) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
          dispatch({
            type: userConstants.CHANGE_PASSWORD_LOGGED_FAILURE,
            response,
          });
        } else {
          dispatch({
            type: userConstants.CHANGE_PASSWORD_LOGGED_SUCCESS,
            response: response.data,
          });
          const passwordEncrypted = btoa(data.password);
          const secureKeyEncrypted = btoa(`${passwordEncrypted}:${config.rememberkey}`);
          localStorage.setItem('password', secureKeyEncrypted);
        }
      }
    },
  );
};

export const resetPassword = (data) => (dispatch) => {
  userService.resetPassword(data).then(
    (response) => {
      if (response && Object.keys(response).length > 0) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
          dispatch({
            type: userConstants.RESET_SIGNUP_FAILURE,
          });
        } else {
          dispatch({
            type: userConstants.RESET_SIGNUP,
            response,
          });
        }
      }
    },
    (error) => {
      console.error('Update password error', error);
    },
  );
};

/*export const resetPasswordWithTicket = (queryParams, data, password) => (dispatch) => {
  userService.resetPasswordWithTicket(queryParams, data, password).then(
    (response) => {
      if (response && Object.keys(response).length > 0) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
          if (response?.error?.status === 401) {
            dispatch({ type: userConstants.RESET_PASSWORD_TICKET_FAILURE, response });
          }
        } else {
          dispatch({ type: userConstants.RESET_PASSWORD_TICKET_SUCCESS, response });
          dispatch(logout());
          setTimeout(() => {
            console.error('redireccionar');
            window.location = '/';
          }, 1500);
        }
      }
    },
    (error) => {
      console.error('Reset password error', error);
    },
  );
};*/

export const confirmPassword = ({ confirmToken, newPassword }) => (dispatch) => {
  dispatch({
    type: userConstants.CONFIRM_PASSWORD_REQUEST,
  });
  userService.confirmPassword(confirmToken, newPassword)
    .then((response) => {
      if (response && Object.keys(response).length > 0) {
        if (Object.prototype.hasOwnProperty.call(response, 'error')) {
          dispatch({
            type: userConstants.CONFIRM_PASSWORD_FAILURE,
            response,
          });
        } else {
          dispatch({
            type: userConstants.CONFIRM_PASSWORD_SUCCESS,
            response,
          });
        }
      }
    });
};

export const sessionTimeout = () => (dispatch) => {
  dispatch({
    type: userConstants.SESSION_TIMEOUT,
  });
};

export const resetAlert = () => (dispatch) => {
  dispatch({ type: userConstants.RESET_ALERT });
};
