const messages = {
  400: {
    msg: 'Datos insertados inválidos',
    desc: 'Por favor, verifica que los datos ingresados sean válidos.',
  },
  401: {
    msg: 'Acceso denegado',
    desc: 'No cuentas con los permisos para visualizar el contenido',
  },
  403: {
    msg: 'Acceso denegado',
    desc: 'No tienes tienes el acceso para visualizar el contenido',
  },
  404: {
    msg: 'No se encuentra la información',
    desc: 'No se ha podido acceder al recurso solicitado.',
  },
  405: {
    msg: 'Método no permitido',
    desc: 'No se puede realizar esta acción.',
  },
  429: {
    desc: '',
  },
  500: {
    msg: 'Error interno',
    desc: 'Error interno del servidor, por favor contacte con el equipo de soporte',
  },
  504: {
    msg: 'Tiempo eccedido',
    desc: 'Se ha excedido el tiempo de respuesta, vuelva a intentarlo más tarde',
  },
};

const getMessage = (code) => {
  const strCode = parseInt(code, 10);
  const error = messages[strCode];
  return error ? { msg: error.msg, desc: error.desc } : messages[500];
};

export default getMessage;
