const groupConstants = {

  LIST_ALL_GROUPS_REQUEST: 'LIST_ALL_GROUPS_REQUEST',
  LIST_ALL_GROUPS_SUCCESS: 'LIST_ALL_GROUPS_SUCCESS',
  LIST_ALL_GROUPS_ERROR: 'LIST_ALL_GROUPS_ERROR',

  ASSIGN_GROUP_REQUEST: 'ASSIGN_GROUP_REQUEST',
  ASSIGN_GROUP_SUCCESS: 'ASSIGN_GROUP_SUCCESS',
  ASSIGN_GROUP_ERROR: 'ASSIGN_GROUP_ERROR',

  ASSIGNED_GROUP_REQUEST: 'ASSIGNED_GROUP_REQUEST',
  ASSIGNED_GROUP_SUCCESS: 'ASSIGNED_GROUP_SUCCESS',
  ASSIGNED_GROUP_ERROR: 'ASSIGNED_GROUP_ERROR',

  DELETE_ASSIGNED_GROUP_REQUEST: 'DELETE_ASSIGNED_GROUP_REQUEST',
  DELETE_ASSIGNED_GROUP_SUCCESS: 'DELETE_ASSIGNED_GROUP_SUCCESS',
  DELETE_ASSIGNED_GROUP_ERROR: 'DELETE_ASSIGNED_GROUP_ERROR',

  DETAIL_GROUP_REQUEST: 'DETAIL_GROUP_REQUEST',
  DETAIL_GROUP_SUCCESS: 'DETAIL_GROUP_SUCCESS',
  DETAIL_GROUP_ERROR: 'DETAIL_GROUP_ERROR',

  UPDATE_GROUP_REQUEST: 'UPDATE_GROUP_REQUEST',
  UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_ERROR: 'UPDATE_GROUP_ERROR',

  ADD_GROUP_REQUEST: 'ADD_GROUP_REQUEST',
  ADD_GROUP_SUCCESS: 'ADD_GROUP_SUCCESS',
  ADD_GROUP_ERROR: 'ADD_GROUP_ERROR',

  ADD_PRODUCT_GROUP_REQUEST: 'ADD_PRODUCT_GROUP_REQUEST',
  ADD_PRODUCT_GROUP_SUCCESS: 'ADD_PRODUCT_GROUP_SUCCESS',
  ADD_PRODUCT_GROUP_ERROR: 'ADD_PRODUCT_GROUP_ERROR',

  DELETE_PRODUCT_GROUP_REQUEST: 'DELETE_PRODUCT_GROUP_REQUEST',
  DELETE_PRODUCT_GROUP_SUCCESS: 'DELETE_PRODUCT_GROUP_SUCCESS',
  DELETE_PRODUCT_GROUP_ERROR: 'DELETE_PRODUCT_GROUP_ERROR',

  ASSIGNED_PRODUCT_GROUP_REQUEST: 'ASSIGNED_PRODUCT_GROUP_REQUEST',
  ASSIGNED_PRODUCT_GROUP_SUCCESS: 'ASSIGNED_PRODUCT_GROUP_SUCCESS',
  ASSIGNED_PRODUCT_GROUP_ERROR: 'ASSIGNED_PRODUCT_GROUP_ERROR',

  GET_GROUP_SKIP: 'GET_GROUP_SKIP',
  GET_GROUP_ASSIGNED_SKIP: 'GET_GROUP_ASSIGNED_SKIP',
  GET_PROD_GROUP_SKIP: 'GET_PROD_GROUP_SKIP',

  DELETE_GROUP_REQUEST: 'DELETE_GROUP_REQUEST',
  DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_ERROR: 'DELETE_GROUP_ERROR',

  RESET_GROUP: 'RESET_GROUP',

};

export default groupConstants;
