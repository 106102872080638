import apiConstants from '../constants/apiConstans';
import apiService from '../../services/apiService';

export const getApiContent = () => (dispatch) => {
  apiService.getApiContent().then(
    (response) => {
      dispatch({
        type: apiConstants.GET_ALL_API_SUCCESS,
        payload: response,
      });
    },
    (error) => {
      dispatch({
        type: apiConstants.GET_ALL_API_FAILURE,
        payload: error,
      });
    },
  );
};

export const getApiProducts = (apiId) => (dispatch) => {
  apiService.getApiProducts(apiId).then(
    (response) => {
      dispatch({
        type: apiConstants.GET_API_PRODUCTS_SUCCESS,
        payload: response,
      });
    },
    (error) => {
      dispatch({
        type: apiConstants.GET_API_PRODUCTS_FAILURE,
        payload: error,
      });
    },
  );
};
