const userConstants = {
  SAVE_USER: 'SAVE_USER',

  CONFIRM_ACCOUNT_REQUEST: 'CONFIRM_ACCOUNT_REQUEST',
  CONFIRM_ACCOUNT_SUCCESS: 'CONFIRM_ACCOUNT_SUCCESS',
  CONFIRM_ACCOUNT_FAILURE: 'CONFIRM_ACCOUNT_FAILURE',

  LOGOUT_USER: 'LOGOUT_USER',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  RESET_SIGNUP: 'RESET_SIGNUP',
  RESET_SIGNUP_FAILURE: 'RESET_SIGNUP_FAILURE',
  RESET_ALERT: 'RESET_ALERT',

  RESET_PASSWORD_TICKET_SUCCESS: 'RESET_PASSWORD_TICKET_SUCCESS',
  RESET_PASSWORD_TICKET_FAILURE: 'RESET_PASSWORD_TICKET_FAILURE',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  UPDATE_USER_STATUS_REQUEST: 'UPDATE_USER_STATUS_REQUEST',
  UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
  UPDATE_USER_STATUS_FAILURE: 'UPDATE_USER_STATUS_FAILURE',

  GET_USER_GROUPS_REQUEST: 'GET_USER_GROUPS_REQUEST',
  GET_USER_GROUPS_SUCCESS: 'GET_USER_GROUPS_SUCCESS',
  GET_USER_GROUPS_FAILURE: 'GET_USER_GROUPS_FAILURE',

  HEAD_ETAG_SUCCESS: 'HEAD_ETAG_SUCCESS',
  HEAD_ETAG_FAILURE: 'HEAD_ETAG_FAILURE',

  SESSION_TIMEOUT: 'SESSION_TIMEOUT',

  RESTORE_SIGNUP_REQUEST: 'RESTORE_SIGNUP_REQUEST',
  RESTORE_SIGNUP_SUCCESS: 'RESTORE_SIGNUP_SUCCESS',
  RESTORE_SIGNUP_FAILURE: 'RESTORE_SIGNUP_FAILURE',

  CHANGE_PASSWORD_LOGGED_REQUEST: 'CHANGE_PASSWORD_LOGGED_REQUEST',
  CHANGE_PASSWORD_LOGGED_SUCCESS: 'CHANGE_PASSWORD_LOGGED_SUCCESS',
  CHANGE_PASSWORD_LOGGED_FAILURE: 'CHANGE_PASSWORD_LOGGED_FAILURE',
  CHANGE_PASSWORD_LOGGED_RESET: 'CHANGE_PASSWORD_LOGGED_RESET',

  CONFIRM_PASSWORD_REQUEST: 'CONFIRM_PASSWORD_REQUEST',
  CONFIRM_PASSWORD_SUCCESS: 'CONFIRM_PASSWORD_SUCCESS',
  CONFIRM_PASSWORD_FAILURE: 'CONFIRM_PASSWORD_FAIULURE',

  UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',
};

export default userConstants;

