import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { createTheme, CssBaseline } from '@mui/material';
import { esES } from '@mui/material/locale';

const suraTheme = createTheme(
  {
    typography: {
      fontFamily: ['Arial'].join(','),
    },
  },
  esES,
);

function AppTheme({children}) {
  return (
    <ThemeProvider theme={suraTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default AppTheme;
