import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '../MdIcon/Icon';
import userConstants from '../../redux/constants/userConstats';
import classes from './Alert.module.scss';
import appsConstants from '../../redux/constants/appsConstants';

function Alert({ alert_type, title, msg, css_styles, display }) {
  const dispatch = useDispatch();
  const { responseError, responseRestoreError, responseResetSignup, updateProfileFail } = useSelector((state) => state.user);
  const { custom_padding, custom_margin } = css_styles;
  const [showAlert, setShowAlert] = useState('d-none');
  const [message, setMessage] = useState(msg);
  useEffect(() => {
    if (Object.keys(responseResetSignup).length > 0) {
      setShowAlert('d-block');
      setMessage(['Se ha enviado un correo electrónico de confirmación de cambio de contraseña, siga las instrucciones del correo electrónico para relizar el cambio de contraseña.']);
    } else if (updateProfileFail && Object.keys(updateProfileFail).length > 0) {
      setShowAlert('d-block');
      setMessage(['Sucedio un error al intentar modificar la información, intente nuevamente']);
    } else if (Object.keys(responseError).length > 0) {
      setShowAlert('d-block');
      if (Object.prototype.hasOwnProperty.call(responseError?.error, 'details')) {
        // setMessage(responseError?.error?.details[0]?.message);
        setMessage(msg);
      } else {
        setMessage(msg);
      }
    } else if (display) {
      setShowAlert('d-block');
      setMessage(msg);
    }
  }, [responseError, responseRestoreError, responseResetSignup, updateProfileFail]);

  const handleClose = () => {
    setShowAlert('d-none');
    dispatch({
      type: userConstants.RESET_ALERT,
    });
    dispatch({
      type: appsConstants.RESET_B2C_ALERT,
    });
  };

  let icon = '';
  switch (alert_type) {
    case 'alert__danger': icon = <Icon id='MdReportProblem' />; break;
    case 'alert__success': icon = <Icon id='MdDone' />; break;
    case 'alert__info': icon = <Icon id='MdInfo' />; break;
    default: icon = '';
  }

  return (
    <div className={`${classes.wrapper_alert} ${showAlert}`}>
      <div className={`${classes[alert_type]} ${custom_padding} ${custom_margin}`}>
        <div className={classes.icon}>{ icon }</div>
        <div className={classes.message}>
          { title && <span className='fs__16 font-weight-bold'>{title}</span> }
          <br />
          <span className='fs__14'>{ message }</span>
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className={classes.close} onClick={() => handleClose()}>
          <Icon id='MdClose' />
        </div>
      </div>
    </div>
  );
}

Alert.defaultProps = {
  css_styles: '',
};

export default Alert;
